import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CALCULATED_IMPACT,
  IMPACT_OWNER_FIRSTNAME,
  IMPACT_OWNER_LASTNAME,
  ImpactType,
  Stages,
  ValueCaptureCategory,
  ValueCaptureUseCase,
} from '@/valueCapture/valueCapture.types';
import { UserNameCellProps, UserNameCellRenderer } from '@/valueCapture/cellRenderers/UserNameCellRenderer.atom';
import {
  formatNumberAsValue,
  getCategoryDisplayString,
  getCustomCategoryTitle,
  getImpactStageIcon,
  getImpactTypeDisplayString,
  getStageDisplayString,
} from '@/valueCapture/valueCapture.utilities';
import { Button, Icon } from '@seeqdev/qomponents';
import { ICellRendererParams } from '@ag-grid-community/core/dist/types/src/rendering/cellRenderers/iCellRenderer';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { SelectedWorksheetDisplayAndLink } from '@/valueCapture/SelectedWorksheetDisplayAndLink.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqValueCaptureStore } from '@/core/core.stores';
import { deleteTableRows } from '@/tableDefinitionEditor/tableDefinition.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast } from '@/utilities/toast.utilities';
import { setForceTableReloadFlag } from '@/valueCapture/valueCapture.actions';

interface ValueCaptureDetailsProps extends ValueCaptureUseCase {
  categories: ValueCaptureCategory[] | undefined;
  [key: string]: any;
  onClose: () => void;
  onEdit?: () => void;
  editable?: boolean;
}

export const ValueCaptureDetailHeader: React.FunctionComponent<ValueCaptureUseCase> = (props) => {
  const params = {
    value: {
      first: props[IMPACT_OWNER_FIRSTNAME]!,
      last: props[IMPACT_OWNER_LASTNAME]!,
    },
  } as ICellRendererParams<any, UserNameCellProps>;

  return (
    <div className="flexRowContainer valueCapture width-maximum">
      <div className="displayFlex flexSpaceBetween flexAlignCenter mb8 flexFill">
        <div className="flexRowContainer">
          <UserNameCellRenderer {...params} />
        </div>

        <div className="flexColumnContainer flexAlignEnd flexAlignCenter borderGray fakeBadge">
          <Icon
            type="theme"
            icon={getImpactStageIcon(props[SeeqNames.ValueCapture.ImpactStatus] as Stages)}
            extraClassNames="mr5 mb3"
          />
          {getStageDisplayString(props[SeeqNames.ValueCapture.ImpactStatus] as Stages)}
        </div>
      </div>
      <h2 className="mb10">{props[SeeqNames.ValueCapture.ImpactName]}</h2>
      <br />
      <SelectedWorksheetDisplayAndLink
        worksheetId={props[SeeqNames.ValueCapture.AssignedToWorksheet]!}
        workbookId={props[SeeqNames.ValueCapture.AssignedToWorkbook]!}
      />
    </div>
  );
};
export const ValueCaptureDetail: React.FunctionComponent<ValueCaptureDetailsProps> = (props) => {
  const { t } = useTranslation();
  const { categories, onClose, onEdit, id } = props;
  const tableId = useFluxPath(sqValueCaptureStore, () => sqValueCaptureStore.tableId);

  const deleteUseCase = async () => {
    try {
      await deleteTableRows(tableId!, [
        {
          itemId: props[SeeqNames.MaterializedTables.ItemIdColumn],
          datumId: props[SeeqNames.MaterializedTables.DatumIdColumn],
        },
      ]);
    } catch (e) {
      errorToast({ messageKey: 'VALUE_CAPTURE.NO_DELETE' });
    }
    setForceTableReloadFlag(true);
    onClose();
  };

  const impact = props[CALCULATED_IMPACT];
  return (
    <div className="flexCenter p25 height-maximum overflowYAuto valueCapture">
      <div className="detailsView flexRowContainer flexFill">
        <div className="flexRowContainer mb10">
          <ContainerWithHTML content={props[SeeqNames.ValueCapture.ImpactDescription]} isBlock />
        </div>
        <hr className="mb6" />
        <div className="container ml0 pl0">
          <div className="row">
            <div className="col">
              <div className="valueCaptureStats">{t('VALUE_CAPTURE.TABLE_HEADERS.SAVINGS_USE_CASE')}</div>
              <p>{impact ? formatNumberAsValue(props[CALCULATED_IMPACT]) : t('VALUE_CAPTURE.NOT_PROVIDED')}</p>
            </div>
            <div className="col">
              <div className="valueCaptureStats">{t('VALUE_CAPTURE.TABLE_HEADERS.TYPE')}</div>
              <p>{getImpactTypeDisplayString(props[SeeqNames.ValueCapture.ImpactSavingsType] as ImpactType)}</p>
            </div>
          </div>

          {categories
            ?.reduce<ValueCaptureCategory[][]>((rows, item, index) => {
              if (index % 2 === 0) {
                rows.push(categories?.slice(index, index + 2));
              }
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div className="row" key={rowIndex}>
                {row.map((item, colIndex) => {
                  const display =
                    (item.category?.id && getCategoryDisplayString(props[item.category.id], item.options)) ?? undefined;
                  return display ? (
                    <div className="col" key={colIndex}>
                      <div className="valueCaptureStats">{getCustomCategoryTitle(item)}</div>
                      <p>{display}</p>
                    </div>
                  ) : null;
                })}
              </div>
            ))}
        </div>
      </div>
      <hr />
      <div className="flexColumnContainer mt25 flexFill">
        <div className="flexColumnContainer flexFill">
          {onEdit && <Button label={t('DELETE')} onClick={deleteUseCase} variant="danger" />}
        </div>
        <Button label={t('CLOSE')} onClick={onClose} extraClassNames="mr10" />
        {onEdit && <Button label={t('EDIT')} onClick={onEdit} variant="theme" />}
      </div>
    </div>
  );
};
