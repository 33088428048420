import {
  CALCULATED_IMPACT,
  IMPACT_OWNER_FIRSTNAME,
  IMPACT_OWNER_LASTNAME,
  MANAGER_FIRSTNAME,
  MANAGER_LASTNAME,
  VALUE_CAPTURE_CATEGORY_DISPLAY_NAME,
  VALUE_CAPTURE_LABEL_ID,
  ValueCaptureCategory,
  ValueCaptureUseCase,
} from '@/valueCapture/valueCapture.types';
import { ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core/dist/types/src/entities/colDef';
import { t } from 'i18next';
import _ from 'lodash';
import { UseCaseNameCellRenderer } from '@/valueCapture/cellRenderers/UseCaseNameCellRenderer.atom';
import { UseCaseStatusCellRenderer } from '@/valueCapture/cellRenderers/UseCaseStatusCellRenderer.atom';
import { UserNameCellRenderer } from '@/valueCapture/cellRenderers/UserNameCellRenderer.atom';
import { ColDef } from '@ag-grid-community/core';
import {
  formatNumberAsValue,
  getCategoryDisplayString,
  getCustomCategoryTitle,
  getImpactTypeDisplayString,
  getStageDisplayString,
} from '@/valueCapture/valueCapture.utilities';
import { sqFormulasApi, sqGraphQLApi } from '@/sdk';
import { MAX_TABLE_ROWS } from '@/main/app.constants';
import { MaterializedTablePropertyColumnInput } from '@/tableDefinitionEditor/tableDefinition.types';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export function getColumnDefinitions(categories: ValueCaptureCategory[] | undefined): ColDef[] {
  const categoryCustomColumns = categories
    ? categories.map((category: ValueCaptureCategory) => {
        const cat = category.category as any;
        const fieldName = getCustomCategoryTitle(category);
        const options = category.options;
        return {
          field: cat.id,
          headerName: fieldName,
          flex: 1,
          floatingFilter: true,
          filter: 'agSetColumnFilter',
          valueGetter: (rowData: ValueGetterParams<string, string>) => {
            if (!rowData || !rowData.data) return;
            return getCategoryDisplayString(rowData.data[cat.id], options, rowData.data[cat.id]);
          },
          context: { canAggregateBy: true },
        };
      })
    : [];
  return [
    { field: 'value', headerName: '# of Reports', hide: true, valueGetter: (rowData: any) => 1 },
    {
      field: SeeqNames.ValueCapture.ImpactName,
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.NAME'),
      flex: 2,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterValueGetter: (params: ValueGetterParams) => `${params.data[SeeqNames.ValueCapture.ImpactName]}`,
      cellRenderer: UseCaseNameCellRenderer,
      valueGetter: (rowData) => {
        if (!rowData || !rowData.data) return;
        return {
          name: rowData.data[SeeqNames.ValueCapture.ImpactName],
          worksheetId: rowData.data[SeeqNames.ValueCapture.AssignedToWorksheet],
          type: rowData.data[SeeqNames.ValueCapture.ImpactBasedOn],
        };
      },
    },
    {
      field: SeeqNames.ValueCapture.ImpactStatus,
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.STATUS'),
      flex: 1,
      floatingFilter: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: any) => {
          return getStageDisplayString(params.value);
        },
      },
      valueGetter: (rowData) => {
        if (!rowData || !rowData.data) return;
        return rowData.data[SeeqNames.ValueCapture.ImpactStatus]?.toLowerCase().trim();
      },
      cellRenderer: UseCaseStatusCellRenderer,
      context: { canAggregateBy: true },
    },
    ...categoryCustomColumns,
    {
      field: SeeqNames.ValueCapture.ImpactAsset,
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.ASSET'),
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      aggFunc: 'sum',
      context: { canAggregateBy: true },
    },
    {
      field: SeeqNames.ValueCapture.ImpactOwner,
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.OWNER'),
      cellRenderer: UserNameCellRenderer,
      valueGetter: (rowData) => {
        if (!rowData || !rowData.data) return;
        return {
          first: rowData.data[IMPACT_OWNER_FIRSTNAME],
          last: rowData.data[IMPACT_OWNER_LASTNAME],
        };
      },
      flex: 2,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterValueGetter: (params: ValueGetterParams) =>
        `${params.data[IMPACT_OWNER_FIRSTNAME]} ${params.data[IMPACT_OWNER_LASTNAME]}`,
    },
    {
      field: SeeqNames.ValueCapture.Manager,
      filter: 'agSetColumnFilter',
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.MANAGER'),
      flex: 2,
      valueGetter: (rowData) => {
        if (!rowData || !rowData.data || !rowData.node) return;
        if (rowData.node.rowPinned === 'bottom') return '';
        const first = rowData.data[MANAGER_FIRSTNAME];
        const last = rowData.data[MANAGER_LASTNAME];
        return `${first ?? ''} ${last ?? ''}`;
      },
      context: { canAggregateBy: true },
    },

    {
      field: SeeqNames.ValueCapture.ImpactSavingsType,
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.TYPE'),
      flex: 2,
      valueGetter: (rowData) => {
        if (!rowData || !rowData.data || !rowData.node) return;
        if (rowData.node.rowPinned === 'bottom') return '';
        return getImpactTypeDisplayString(rowData.data[SeeqNames.ValueCapture.ImpactSavingsType]);
      },
      floatingFilter: true,
      filter: 'agSetColumnFilter',
      context: { canAggregateBy: true },
    },
    {
      field: CALCULATED_IMPACT,
      headerName: t('VALUE_CAPTURE.TABLE_HEADERS.SAVINGS_USE_CASE'),
      valueFormatter: (params: ValueFormatterParams) => formatNumberAsValue(params.value),
      cellClass: 'right-align',
      headerClass: 'pull-right',
      valueGetter: (rowData) => {
        if (!rowData || !rowData.data || !rowData.node) return;
        if (rowData.node.rowPinned === 'bottom') return rowData.data[CALCULATED_IMPACT];
        return rowData.data[CALCULATED_IMPACT] ? rowData.data[CALCULATED_IMPACT] : 0;
      },
      aggFunc: 'sum',
      flex: 1,
    },
  ];
}

/**
 * The impact is calculated based on a Formula that is stored on the use case.
 */
export async function decorateMaterializedTableWithImpactValue(
  tableData: ValueCaptureUseCase[],
  startDate: Date,
  endDate: Date,
) {
  return await Promise.all(
    tableData.map(async (row: any) => {
      try {
        const formula = row[SeeqNames.ValueCapture.ImpactFormula];
        if (!formula) return row;
        const parameters = [];
        if (row[SeeqNames.ValueCapture.ImpactSavingsType] === 'customCalc') {
          parameters.push(`customSavingsSignal=${row[SeeqNames.ValueCapture.ImpactFormulaParam]}`);
        }

        const formulaResponse = await sqFormulasApi.runFormula({
          parameters,
          formula: `(${formula}).toSignal('Impact Estimate').sum(capsule('${startDate?.toISOString()}','${endDate?.toISOString()}'))`,
        });
        const impact: number = formulaResponse.data?.scalar?.value;
        row[CALCULATED_IMPACT] = impact;
      } catch (error) {
        console.log(error);
      }
      return row;
    }),
  );
}

/**
 * This returns the Materialized Table for the Value Capture table without the "likes" rows
 * this is accomplished by filtering to only the desired category context labels, not the "like" label.
 * In theory, we should be able to filter by category_id but that doesn't work yet
 * (https://seeq.atlassian.net/browse/CRAB-45806)
 *
 * The GraphQL endpoint returns an array of headers and an array of rows where rows can be duplicated.
 * There will always be one row per assigned context label (that's why we filter out the "like" label), but there can
 * also be duplicates for no apparent reason :shrug:
 *
 * The AGGrid table expects context category ids to be provided as the column name, and the value renderer will
 * parse the context label id to display the label name.
 */
export async function getTableWithoutLikes(tableId: string, categories: ValueCaptureCategory[]) {
  const allLabelIds = _.chain(categories)
    .map((category) => category.options)
    .map((options) => options?.map((opt) => opt.value))
    .flatten()
    .value();

  const properties: MaterializedTablePropertyColumnInput[] = [
    {
      uuidColumn: SeeqNames.ValueCapture.ImpactOwner,
      propertyNames: [SeeqNames.Properties.FirstName, SeeqNames.Properties.LastName],
    },
    {
      uuidColumn: SeeqNames.ValueCapture.Manager,
      propertyNames: [SeeqNames.Properties.FirstName, SeeqNames.Properties.LastName],
    },
  ];

  const { data } = await sqGraphQLApi.graphql({
    query:
      '\nquery GetTable($id: String!, $filter: FilterInput, $limit: Int!, $columnsToInclude: [String!],' +
      ' $propertiesToInclude: [PropertiesForItemUUIDColumnInput!], ) {\n' +
      '  table(id: $id, filter: $filter, limit: $limit, columnsToInclude: $columnsToInclude, propertiesToInclude: $propertiesToInclude)' +
      ' {\n    rows\n' +
      '    headers {\n      name\n      type\n    }\n    hasMore\n  }\n}\n',
    variables: {
      id: tableId,
      limit: MAX_TABLE_ROWS,
      columnsToInclude: [],
      propertiesToInclude: properties,
      // filter: {
      //    we need to have a "NOT" option for the filterType so we can filter out the like ones that way - we
      //    can't use allLabelIds above as this will lead to un-categorized reports not being displayed
      //   valueFilter: {
      //     columnName: 'context_labels.label_id',
      //     filterType: 'IN',
      //     value: allLabelIds,
      //   },
      // },
    },
  });

  const transformedData = transformTableResponseToObject(data.data.table?.rows, data.data.table?.headers);
  // we will now have multiple rows (each context category on its own row)
  const withLabels = mergeContextLabels(transformedData);
  return mergeRows(withLabels);
}

/**
 * Little helper function that creates an object with the column name as the key and the value as the value
 */
function transformTableResponseToObject(rows: any[], headers: Record<string, any>) {
  return rows.map((row) =>
    headers.reduce((obj: any, header: Record<string, any>, index: number) => {
      obj[header.name] = row[index] || null;
      return obj;
    }, {}),
  );
}

function mergeContextLabels(data: Record<string, any>[]) {
  return data.map((row) => {
    const categoryId = row['context_labels.category_id'];
    row[categoryId] = row['context_labels.label_id'];
    row[`${categoryId}_${VALUE_CAPTURE_LABEL_ID}`] = row['context_labels.id'];
    row[`${VALUE_CAPTURE_CATEGORY_DISPLAY_NAME}_${categoryId}`] = row['context_labels.label_name'];
    return row;
  });
}

function mergeRows(data: Record<string, any>[]) {
  return Object.values(
    data.reduce((acc, item) => {
      const key = `${item[SeeqNames.MaterializedTables.DatumIdColumn]}-${
        item[SeeqNames.MaterializedTables.ItemIdColumn]
      }`;
      if (!acc[key]) {
        acc[key] = {
          [SeeqNames.MaterializedTables.DatumIdColumn]: item[SeeqNames.MaterializedTables.DatumIdColumn],
          [SeeqNames.MaterializedTables.ItemIdColumn]: item[SeeqNames.MaterializedTables.ItemIdColumn],
        };
      }
      Object.assign(acc[key], item);
      return acc;
    }, {}),
  );
}

export async function getImpactReportByWorksheet(tableId: string, worksheetId: string, workbookId: string) {
  const properties: MaterializedTablePropertyColumnInput[] = [
    {
      uuidColumn: SeeqNames.ValueCapture.ImpactOwner,
      propertyNames: [SeeqNames.Properties.FirstName, SeeqNames.Properties.LastName],
    },
    {
      uuidColumn: SeeqNames.ValueCapture.Manager,
      propertyNames: [SeeqNames.Properties.FirstName, SeeqNames.Properties.LastName],
    },
  ];
  const { data } = await sqGraphQLApi.graphql({
    query:
      '\nquery GetTable($id: String!, $filter: FilterInput, $limit: Int!, $columnsToInclude: [String!], $propertiesToInclude: [PropertiesForItemUUIDColumnInput!]) {\n' +
      '  table(id: $id, filter: $filter, limit: $limit, columnsToInclude: $columnsToInclude, propertiesToInclude: $propertiesToInclude) {\n    rows\n' +
      '    headers {\n      name\n      type\n    }\n    hasMore\n  }\n}\n',
    variables: {
      id: tableId,
      limit: MAX_TABLE_ROWS,
      columnsToInclude: [],
      propertiesToInclude: properties,
      filter: {
        compositeFilter: {
          filter1: {
            valueFilter: {
              columnName: SeeqNames.ValueCapture.AssignedToWorksheet,
              filterType: 'IN',
              value: [worksheetId],
            },
          },
          filter2: {
            valueFilter: {
              columnName: SeeqNames.ValueCapture.AssignedToWorkbook,
              filterType: 'IN',
              value: [workbookId],
            },
          },
          operation: 'AND',
        },
      },
    },
  });
  const transformedData = transformTableResponseToObject(data.data.table?.rows, data.data.table?.headers);
  // we will now have multiple rows (each context category on its own row)
  const withLabels = mergeContextLabels(transformedData);
  const mergedData = mergeRows(withLabels);
  return mergedData[0];
}
