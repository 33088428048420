import React, { useEffect, useState } from 'react';
import { availableStages } from '@/valueCapture/valueCapture.types';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqValueCaptureStore, sqWorkbenchStore } from '@/core/core.stores';
import { useAsyncEffect } from 'rooks';
import { getStageIndex, initializeValueCaptureCategories } from '@/valueCapture/valueCapture.utilities';
import { ValueCaptureForm, ValueCaptureHeader } from '@/valueCapture/ValueCaptureForm.page';
import { Modal } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { SeeqNames } from '@/main/app.constants.seeqnames';

interface ValueCaptureModalProps {
  closeFn: (reloadTable?: boolean) => void;
  existingReport?: Record<string, any>;
}

export const ValueCaptureEditModal: React.FunctionComponent<ValueCaptureModalProps> = ({ closeFn, existingReport }) => {
  const maxStageIndex = availableStages?.length;
  const { t } = useTranslation();
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);
  const [workbookId, setWorkbookId] = useState<string>(
    existingReport ? existingReport[SeeqNames.ValueCapture.AssignedToWorkbook] : '',
  );
  const [worksheetId, setWorksheetId] = useState<string>(
    existingReport ? existingReport[SeeqNames.ValueCapture.AssignedToWorksheet] : '',
  );
  const [stageIndex, setStageIndex] = useState(0);
  const categories = useFluxPath(sqValueCaptureStore, () => sqValueCaptureStore.categories);

  useEffect(() => {
    if (existingReport) return;
    setWorkbookId(stateParams?.workbookId);
    setWorksheetId(stateParams?.worksheetId);
  }, [stateParams]);

  useAsyncEffect(async () => {
    if (!categories) {
      await initializeValueCaptureCategories();
    }
  }, []);

  useEffect(() => {
    if (!existingReport) return;
    const idx = getStageIndex(existingReport[SeeqNames.ValueCapture.ImpactStatus]);
    setStageIndex(idx < maxStageIndex - 1 ? idx + 1 : idx);
  }, [existingReport]);

  return categories ? (
    <Modal
      hideFooterButtons={true}
      open={true}
      size="4xl"
      onClose={closeFn}
      title={t('VALUE_CAPTURE.CAPTURE_IMPACT')}
      customHeader={
        <ValueCaptureHeader
          workbookId={workbookId}
          worksheetId={worksheetId}
          stageIndex={stageIndex}
          setStageIndex={setStageIndex}
          setWorkbookId={setWorkbookId}
          setWorksheetId={setWorksheetId}
        />
      }>
      <div className="valueCapture valueCaptureModalHeight">
        <ValueCaptureForm
          categories={categories}
          report={existingReport}
          stageIndex={stageIndex}
          workbookId={workbookId}
          worksheetId={worksheetId}
          setWorkbookId={setWorkbookId}
          setWorksheetId={setWorksheetId}
          setStageIndex={setStageIndex}
          closeFn={closeFn}
        />
      </div>
    </Modal>
  ) : null;
};
